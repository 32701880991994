var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"500px","max-height":"500px"}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"9"}},[_c('CustomAutoCompleteField',{attrs:{"id":"clients","inputPlaceholder":"Clients","optionItems":_vm.clients,"optionText":"name","optionValue":"id"},on:{"change":function($event){return _vm.handleAutoCompleteChange(
                            'assignItemPayload',
                            null,
                            null,
                            $event
                        )},"intersect":function($event){return _vm.retrieveItemUnassigned(
                            'name',
                            _vm.retrieveClientUnassignedOps
                        )}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('CustomButton',{attrs:{"btnColour":"primary","btnIsLoading":_vm.assignItemOps.isLoading,"btnLabel":"Add","tooltipMsg":"Add Client"},on:{"click":function($event){return _vm.assignItem(_vm.customer, _vm.assignItemPayload)}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('CustomTextField',{attrs:{"clearable":"","id":"search","inputIcon":"mdi-magnify","inputPlaceholder":"Search...","inputValue":_vm.search},on:{"input":function($event){return _vm.updateLocalForm($event, 'search')},"click:clear":function($event){_vm.search = ''}}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-data-table',{attrs:{"footer-props":_vm.footers,"headers":_vm.headers,"items":_vm.customer.clients,"loading-text:":"Loading...","no-data-text":"No data so far.","no-results-text":"No matches found.","options":_vm.options,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","tile":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.showUnassignDialog(item)}}},[_c('v-list-item-icon',{staticClass:"avsb-table-action-icons"},[_c('v-icon',{attrs:{"dense":""}},[_vm._v(" mdi-close-circle ")])],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mb-0"},'div',attrs,false),on),[_c('span',[_vm._v("Unassign")])])]}}],null,true)},[_c('span',[_vm._v("Unassign Client")])])],1)],1)],1)],1)]}}])})],1)],1)],1),_c('CustomActionConfirmationDialog',{attrs:{"dlgConfirmColour":"error","dlgConfirmMessage":"Unassign","dlgHeader":"Unassign Client","dlgLoading":_vm.unassignOps.isLoading,"dlgShow":_vm.unassignOps.dialog},on:{"closeDialog":_vm.hideUnassignDialog,"actionCancel":_vm.hideUnassignDialog,"actionConfirm":function($event){return _vm.unassignItem(_vm.customer, _vm.unassignPayload)}},scopedSlots:_vm._u([{key:"question",fn:function(){return [_c('span',[_vm._v("Are you sure you want to unassign the following client: "),_c('strong',[_vm._v(_vm._s(_vm.unassignPayload.name))])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }