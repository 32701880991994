<template>
    <v-card min-height="500px" max-height="500px">
        <v-card-text>
            <v-row dense align="center">
                <v-col cols="9">
                    <CustomAutoCompleteField
                        id="clients"
                        inputPlaceholder="Clients"
                        :optionItems="clients"
                        optionText="name"
                        optionValue="id"
                        @change="
                            handleAutoCompleteChange(
                                'assignItemPayload',
                                null,
                                null,
                                $event
                            )
                        "
                        @intersect="
                            retrieveItemUnassigned(
                                'name',
                                retrieveClientUnassignedOps
                            )
                        "
                    />
                </v-col>
                <v-col cols="3">
                    <CustomButton
                        btnColour="primary"
                        :btnIsLoading="assignItemOps.isLoading"
                        btnLabel="Add"
                        tooltipMsg="Add Client"
                        @click="assignItem(customer, assignItemPayload)"
                    />
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12">
                    <CustomTextField
                        clearable
                        id="search"
                        inputIcon="mdi-magnify"
                        inputPlaceholder="Search..."
                        :inputValue="search"
                        @input="updateLocalForm($event, 'search')"
                        @click:clear="search = ''"
                    />
                </v-col>
            </v-row>

            <v-row dense>
                <v-col>
                    <v-data-table
                        :footer-props="footers"
                        :headers="headers"
                        :items="customer.clients"
                        loading-text:="Loading..."
                        no-data-text="No data so far."
                        no-results-text="No matches found."
                        :options.sync="options"
                        :search="search"
                    >
                        <!-- Item actions -->
                        <template v-slot:item.actions="{ item }">
                            <!-- More actions -->
                            <v-menu close-on-click offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        small
                                        tile
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <!-- Unassign item option-->
                                    <v-list-item
                                        @click="showUnassignDialog(item)"
                                        dense
                                    >
                                        <v-list-item-icon
                                            class="avsb-table-action-icons"
                                        >
                                            <v-icon dense>
                                                mdi-close-circle
                                            </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-tooltip top>
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <div
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        class="mb-0"
                                                    >
                                                        <span>Unassign</span>
                                                    </div>
                                                </template>
                                                <span>Unassign Client</span>
                                            </v-tooltip>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>

        <!-- Dialogs -->
        <!-- Unassign dialog -->
        <CustomActionConfirmationDialog
            dlgConfirmColour="error"
            dlgConfirmMessage="Unassign"
            dlgHeader="Unassign Client"
            :dlgLoading="unassignOps.isLoading"
            :dlgShow="unassignOps.dialog"
            @closeDialog="hideUnassignDialog"
            @actionCancel="hideUnassignDialog"
            @actionConfirm="unassignItem(customer, unassignPayload)"
        >
            <template v-slot:question>
                <span
                    >Are you sure you want to unassign the following client:
                    <strong>{{ unassignPayload.name }}</strong>
                </span>
            </template>
        </CustomActionConfirmationDialog>
    </v-card>
</template>


<script>
import { mapState } from "vuex";

export default {
    components: {
        CustomActionConfirmationDialog: () =>
            import("@/components/dialogs/customActionConfirmationDialog.vue"),
    },
    computed: {
        ...mapState("customersAdminMgmt", ["customer"]),
        ...mapState("clientsAdminMgmt", [
            "assignItemOps",
            "assignItemPayload",
            "clients",
            "retrieveClientUnassignedOps",
            "unassignOps",
            "unassignPayload",
        ]),
    },
    data: () => ({
        footers: {
            "disable-items-per-page": true,
            "items-per-page-options": [5],
        },
        headers: [
            { sortable: true, text: "Name", value: "name" },
            { sortable: false, text: "Actions", value: "actions" },
        ],
        options: {},
        search: null,
    }),
    methods: {
        updateLocalForm(event, form) {
            if (event instanceof InputEvent) {
                this[form] = event.target.value;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this[form] = event;
            }
        },
        retrieveItemUnassigned(order_by, meta) {
            let options = { order_by, ...meta };
            this.$store.dispatch(
                "clientsAdminMgmt/retrieveItemsUnassigned",
                options
            );
        },
        async unassignItem(customer, client) {
            let response = await this.$store.dispatch(
                "clientsAdminMgmt/unassignItem",
                {
                    id: client.id,
                    client: {
                        name: client.name,
                        customer_id: null,
                    },
                }
            );

            if (response.status === 200) {
                this.$store.dispatch("customersAdminMgmt/retrieveItem", {
                    id: customer.id,
                });

                this.$store.commit("customersAdminMgmt/STRIKE_ITEM", {
                    customer: { ...customer },
                    client: { ...client },
                });
            }
        },
        async assignItem(customer, client) {
            let response = await this.$store.dispatch(
                "clientsAdminMgmt/assignItem",
                {
                    id: client.id,
                    client: {
                        name: client.name,
                        customer_id: customer.id,
                    },
                }
            );

            if (response) {
                this.$store.dispatch("customersAdminMgmt/retrieveItem", {
                    id: response.data.customer_id,
                });
            }
        },
        handleAutoCompleteChange(form, name, property, item) {
            let payload = {
                form: form,
                name: name,
                property: property,
                item: item,
            };
            this.$store.commit("clientsAdminMgmt/UPDATE_ITEM_FORM", {
                payload,
            });
        },
        // Dialog operations
        showUnassignDialog(item) {
            let payload = { form: "unassignPayload", item: item };
            this.$store.commit("clientsAdminMgmt/POPULATE_ITEM", payload);
            this.$store.commit("clientsAdminMgmt/SHOW_UNASSIGN_OPS_DIALOG");
        },
        hideUnassignDialog() {
            this.$store.commit("clientsAdminMgmt/HIDE_UNASSIGN_OPS_DIALOG");
        },
    },
    name: "TabCustomerClients",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {
        this.retrieveItemUnassigned("name", this.retrieveClientUnassignedOps);
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>